$font: 'Gilroy', sans-serif;
$font-pangram: 'PP Pangram Sans';

$bg-body: #FFF;
//$bg-gray-light: #F8FAFC;
$bg-gray-light: #F7F8FC;

$color-body: #090A0C;
$color-violet: #413A7D;
$color-green: #20AD96;
$color-green-hover: #12816F;
$color-light-green: #E5F3F1;
$color-yellow: #FEC84B;
$color-gray: #ABADB8;
$color-light-gray: #F7F8FC;
$color-error: #EF7489;



$width-container: 1300px;
$pd-container: 20px;
$pd-container-smx: 15px;

$md-desktop-lg: 1699.98px;
$md-desktop-md: 1399.98px;
$md-desktop: 1199.98px;
$md-tablet: 1023.98px;
$md-tablet-sm: 767.98px;
$md-mobile-lg: 609.98px;
$md-mobile: 478.98px;
$md-mobile-sm: 399.98px;
$md-mobile-smx: 359.98px;

$header-h: 100px;
$header-h-t: 80px;
$header-h-m: 50px;