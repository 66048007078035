/**************************************************************
swiper
**************************************************************/
.swiper {}

.swiper-slide {
  height: auto;
}

.swiper-pagination {
  display: flex;
  &-after{
    position: static;
    margin-top: 28px;
    justify-content: center;
  }
  &-bullet {
    margin: 0 7px !important;
    width: 10px;
    height: 10px;
    background: #E1E2E8;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    .swiper-pagination-after & {
      background: #E1E2E8;
    }
    &-active {
      background: $color-violet !important;
    }
    .swiper-pagination-yellow &-active{
      background: $color-yellow !important;
    }
  }

  &-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }

  @media (max-width: $md-mobile-lg) {
    &-bullet {
      width: 8px; height: 8px;
      margin: 0 2px !important;
    }
  }
}

.swiper-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  left: 0;
  margin: 0;
  color: $color-body;
  z-index: 10;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  &-next {
    left: auto;
    right: 0;
    transform: translate(50%, -50%);
  }

  &:after {
    display: none;
  }

  .icon {
    position: relative;
    z-index: 2;
    width: 35%;
  }


  @media (any-hover: hover) {
    &:hover {
      color: #fff;
      background: $color-violet;
    }
  }

  &.swiper-button-disabled {
    cursor: default;
    opacity: 1;
    // pointer-events: initial;
  }

}

.swiper-fraction {
  text-align: center;
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}
.swiper-pagination-total {
  font-size: 16px;
  opacity: 0.7;
}
.swiper-pagination-current {
  font-size: 32px;
  font-weight: bold;
  margin-right: 6px;
}


.swiper-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  gap: 20px;
  .swiper-fraction {
      width: auto;
  }
  .swiper-button {
    position: static;
    transform: initial;
  }
}

.swiper-scrollbar {
  background: none;
}

@media (max-width: $md-desktop-md) {
  .swiper-button {
    width: 42px; height: 42px;
  }
}
@media (max-width: $md-mobile-lg) {
  .swiper-nav {
    gap: 8px;
  }
  .swiper-button {
    width: 36px; height: 36px;
  }
}
