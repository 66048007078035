@import "inc/core";

@import "fonts";
@import "reset";
@import "common";
@import "libs";
@import "forms";
@import "popup";
@import "header";
@import "footer";



/**************************************************************

**************************************************************/
.hero {
    position: relative;
    z-index: 2;
    overflow: hidden; 
    padding-bottom: 100px;
    & + * {
        margin-top: 0 !important;
    }
    &::after {
        @include abs-all;
        background: url(../images/hero/bg.svg) no-repeat top center;
        // background-size: 100%;
        max-height: 80%;
        content: '';
        z-index: -1;
    }
    &__inner {
        padding-top: 30px;
    }
    &__title {
        text-align: center;
        font-size: 80px;
        font-family: $font-pangram;
        font-weight: 300;
        line-height: 1.3;
        animation: hero-title 1.5s ease-in-out;
    }
    &__intro {
        position: relative;
        max-width: 240px;
        margin-left: 50px;
        animation: hero-intro 1.5s ease-in-out;
        &::before {
            width: 44px; height: 44px;
            position: absolute;
            left: 45px; bottom: 100%;
            margin-bottom: 43px;
            background: url(../images/icons/smile.svg) no-repeat;
            background-size: 100%;
            content: '';
            animation: hero-intro-smile 1.5s ease-in-out;
        }
    }
    &__photos {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::after {
            width: 44px; height: 44px;
            position: absolute;
            left: 100%; bottom: 30%;
            margin-left: 37px;
            background: url(../images/icons/smile-2.svg) no-repeat;
            background-size: 100%;
            content: '';
        }
        &-col {
            // flex: 1 0 0;
            margin: 0 10px;
            flex: 0 0 auto;
            width: calc( (100% - 4 * 20px) / 5);
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;
            transition: all 1s;
            z-index: 5;
            .animate-start &.col--lg {
                width: 0;
                margin: 0;
                z-index: 0;
            }
        }
        &-img {
            border-radius: 20px;
            padding-bottom: 100%;
            overflow: hidden;
            position: relative;
            box-shadow: 8px 8px 16px 0px rgba(40, 49, 70, 0.20);
            &.img--sm {
                padding-bottom: 50%;
            }
            &.img--lg {
                box-shadow: none;
                padding-bottom: 200%;
                background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 81.94%, rgba(255, 201, 76, 0.20) 100%), #FEC84B;
                display: flex;
                align-items: center;
                justify-content: center;
                // svg {
                //     max-width: 90%;
                //     max-height: 90%;
                //     @include abs-center;
                //     margin-left: 2%;
                // }
                transition: background 0.2s;
                .animate-start & {
                    background: none;
                }
            }
            &.img--1 {
                animation: hero-img-1 1.5s ease-in-out;
            }
            &.img--2 {
                animation: hero-img-2 1.5s ease-in-out;
            }
            &.img--3 {
                animation: hero-img-3 1.5s ease-in-out;
            }
            &.img--4 {
                animation: hero-img-4 1.5s ease-in-out;
            }
            &.img--5 {
                animation: hero-img-5 1.5s ease-in-out;
            }
            &.img--6 {
                animation: hero-img-6 1.5s ease-in-out;
            }
            &.img--7 {
                animation: hero-img-7 1.5s ease-in-out;
            }
            &__wrap {
                @include abs-all;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 5%;
            }
            img {
                @include photo-cover;
            }
        }
    }
    &__bear {
        width: 192px; height: auto;
        &.fixed {
            position: absolute;
            z-index: 2;
            transition: all 1s;
        }
        > * {
            width: 100%; height: auto;
        }
    }
    &__callback {
        position: absolute;
        right: 32px; top: 0;
        background: none;
        border: none;
        display: flex;
        cursor: pointer;
        outline: none;
        animation: hero-callback 1.5s ease-in-out;
        max-width: 160px;
        &-wrap {
            position: relative;
            transform: translateY(-55%);
            background: $color-yellow;
            border-radius: 100%;
            padding: 16px;
            > img {
                animation: circle_anim 6s infinite linear;
            }
        }
        &-arrow {
            @include abs-center;
            width: 30%; height: 30%;
            border-radius: 100%;
            border: 2px solid #FFF;
            color: #FFF;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                width: 45%;
            }
        }
        &-wrap:hover &-arrow {
            // background: $color-green;
            // color: #fff;
            transform: translate(-50%, -50%) scale(1.15);
        }
    }
    @keyframes circle_anim {
        0% {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(-360deg);
                  transform: rotate(-360deg);
        }
      }
    @keyframes hero-title {
        0% { opacity: 0; transform: translateY(-40px); }
        to { opacity: 1; transform: translateY(0); }
    }
    @keyframes hero-intro {
        0% { opacity: 0; transform: translate(-500px, -20px); }
        to {  opacity: 1; transform: translate(0, 0); }
    }
    @keyframes hero-intro-smile {
        0% { opacity: 0; }
        60% {  opacity: 0; }
        100% { opacity: 1; }
    }
    @keyframes hero-callback {
        0% { opacity: 0; transform: translate(500px, -20px); }
        to {  opacity: 1; transform: translate(0, 0); }
    }
    @keyframes hero-img-1 {
        0% { opacity: 0; transform: scale(1.25) translate(-1000px, 0%); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-2 {
        0% { opacity: 0; transform: scale(1.25) translate(-1000px, -150%); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-3 {
        0% { opacity: 0; transform: scale(1.25) translate(-1000px, 100%); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-4 {
        0% { opacity: 0; transform: scale(1.25) translate(0, 1000px); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-5 {
        0% { opacity: 0; transform: scale(1.25) translate(1000px, -150%); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-6 {
        0% { opacity: 0; transform: scale(1.25) translate(1000px, 100%); }
        to {  opacity: 1; transform: scale(1) translate(0, 0); }
    }
    @keyframes hero-img-7 {
        0% { opacity: 0; transform: translate(1000px, 0%); }
        to {  opacity: 1; transform: translate(0, 0); }
    }

    @media (min-width: $md-desktop+0.02px) and (max-height: 850px) {
        &__inner {
            padding-top: 0;
        }
        &__photos {
            margin-top: -30px;
        }
    }
    @media (max-width: $md-desktop-md) {
        padding-bottom: 100px;
        &::after {
            background-position: bottom center;
        }
        &__title {
            font-size: 64px;
        }
        &__intro::before {
            margin-bottom: 3px;
        }
    }
    @media (max-width: $md-desktop) {
        &::after {
        }
        &__intro {
            margin-left: 0;
            font-size: 16px;
            &::before {
                left: 11px;
                margin-bottom: 2px;
            }
        }
        &__photos {
            &::after {
                bottom: 0;
                left: auto; right: 0;
            }
        }
        &__callback {
            width: 120px;
            &-wrap {
                transform: translateY(-85%);
            }
        }
        &__bear {
            width: 150px;
        }
    }
    @media (max-width: $md-tablet) {
        padding-bottom: 80px;
        &::after {
            background-image: url(../images/hero/bg-tablet.svg);
            background-size: cover;
        }
        &__title {
            font-size: 48px;
        }
        &__intro {
            &::before {
                
                width: 30px; height: 30px;
            }
        }
        &__photos {
            margin-top: 10px;
            // gap: 16px;
            &::after {
                width: 30px; height: 30px;
            }
            &-col {
                gap: 16px;
                width: calc( (100% - 4 * 16px) / 5);
                margin: 0 8px;
            }
        }
        &__callback {
            right: 10px;
        }
        &__bear {
            width: 90%;
        }
    }
    @media (max-width: $md-tablet-sm) {
        padding-bottom: 40px;
        &__title {
            font-size: 36px;
            .nn {
                display: block;
                margin-top: 10px;
            }
        }
        &__intro {
            display: none;
        }
        &__callback {
            display: none;
        }
        &__photos {
            margin-top: 40px;
            // gap: 10px;
            &-col {
                gap: 10px;
                margin: 0 5px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &::after {
            height: 420px;
            background-size: initial;
        }
        &__inner {
            margin: 0 -10px;
        }
        &__photos {
            margin: 40px 0px 0;
            gap: 10px;
            &::after {
                display: none;
            }
            &-col {
                width: auto;
                flex: 1 0 0;
                margin: 0;
            }
            .col--lg {
                flex: 0 0 auto;
                width: 38%;
            }
        }
        
    }
    @media (max-width: $md-mobile) {
        &__title {
            font-size: 28px;

        }
    }
    @media (max-width: $md-mobile-smx) {
        &__title {
            font-size: 24px;

        }
    }
}




/**************************************************************
Направления работы
**************************************************************/
.areasw {
    &__inner {
        padding: 28px 40px 36px;
        margin: 0 -40px;
        background: $bg-gray-light;
        border-radius: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        position: relative;
        z-index: 5;
    }
    &__item {
        padding: 25px 25px 40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $color-light-green;
        }
        &-icon {
            flex: 0 0 auto;
            width: 70px; height: 70px;
            border-radius: 10px;
            background: #fff;
            position: relative;
            transition: all 0.3s ease-in-out;
            img {
                position: absolute;
                right: -5px; bottom: -5px;
                max-height: 100%;
            }
        }
        &:hover &-icon {
            background: rgba(255, 255, 255, 0.5);
        }
        &-info {
            min-height: 120px;
        }
    }

    @media (max-width: $md-desktop-md) {
        &__inner {
            margin: 0 -20px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media (max-width: $md-desktop) {
        &__item {
            padding: 20px;
            &-icon {
                width: 52px; height: 52px;
                img {
                    bottom: -4px; right: -4px;
                }
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__inner {
            padding: 20px;
            grid-template-columns: 1fr 1fr;
            margin: 0;
        }
        &__item {
            min-height: 150px;
            flex-direction: row;
            gap: 24px;
            &-info {
                min-height: 1px;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__item {
            padding: 10px;
            min-height: 100px;
            gap: 10px;
            &-icon {
                width: 38px; height: 38px;
                img {
                    max-width: 32px;
                    right: -3px; bottom: -3px;
                }
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__inner {
            grid-template-columns: 1fr;
            grid-gap: 22px;
        }
        &__item {
            min-height: 1px;
        }
    }
}




/**************************************************************

**************************************************************/
.mabout {
    &__inner {
        max-width: 1162px;
        margin: 0 auto;
        display: flex;
        gap: 60px;
    }
    &__photo {
        width: 46%;
        padding-bottom: 46%;
        position: relative;
        overflow: hidden;
        border-radius: 20px;
        z-index: 5;
        img {
            @include photo-cover
        }
    }
    &__info {
        flex: 1 0 0;
    }
    &__text {
        &-vv {
            color: $color-violet;
        }
        > * + * {
            margin-top: 20px;
        }
    }
    &__buttons {
        margin-top: 30px;
    }
    @media (max-width: $md-desktop-md) {
        &__inner {
            gap: 40px;
        }
        &__text-vv {
            font-size: 16px;
        }
    }
    @media (max-width: $md-tablet) {        
        &__inner {
            gap: 20px;
            align-items: center;
        }    
        &__photo {
            width: 49%;
            padding-bottom: 49%;
        }
        &__text {
            > * + * {
                margin-top: 10px;
            }
        }
        &__buttons {
            margin-top: 20px;
            .btn {
                
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__inner {
            flex-direction: column;
            gap: 30px;
        }
        &__photo {
            width: 80%;
            padding-bottom: 60%;
        }
        &__text {
            .section__head {
                display: block !important;
            }
            &-vv {
                font-size: 14px;
            }
            > * + * {
                margin-top: 16px;
            }
        }
    }
    @media (max-width: $md-mobile) {
        &__photo {
            width: 100%;
            padding-bottom: 100%;
        }
        &__buttons {
            .btn {
                width: 100%;
            }
        }
    }
}



/**************************************************************
Почему выбирают нас?
**************************************************************/
.whyme {
    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 40px;
        background: $bg-gray-light;
        border-radius: 10px;
        &.item--nobg {
            background: none;
            padding: 0;
        }
        &-head {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            min-height: 60px;
        }
        &-icon {
            flex: 0 0 auto;
            width: 26px; height: 26px;
            border-radius: 8px;
            background: #fff;
            position: relative;
            img {
                position: absolute;
                right: -6px; bottom: -2px;
                max-width: 90%;
            }
        }
        &-info {
            margin-top: auto;
        }
        &-smile {
            padding: 0 10px 54px;
            > * {
                width: 100%;
                height: auto;
            }
        }
        &-buttons {
            margin-top: 30px;
        }
    }
    @media (max-width: $md-desktop) {
        &__item {
            gap: 13px;
            padding: 20px;
            &-head {
                min-height: 1px;
            }
            &-icon {
                width: 20px; height: 20px;
                img {
                    right: -4px;
                }
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__items {
            grid-template-columns: 1fr 1fr;
        }
        &__item {
            min-height: 354px;
            &.item--btn {
                order: 10;
                padding: 0 20px;
            }
            &.item--smile {
                align-items: center;
                justify-content: center;
            }
            &.item--smile &-info {
                margin: 0;
            }
            &-smile {
                padding: 0 0 15px;
                max-width: 240px;
            }
            &-buttons {
                margin-top: 20px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        overflow: hidden;
        &__carousel {
            overflow: initial;
        }
        &__items {
            display: flex;
            grid-gap: 0;
        }
        &__item {
            gap: 30px;
            min-height: 1px;
            &.item--btn {
                margin-top: 20px;
                padding: 0;
            }
            &-buttons {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: $md-mobile) {
        &__item {
            &-buttons {
                .btn {
                    width: 100%;
                }
            }
        }
    }
}




/**************************************************************

**************************************************************/
.services {
    overflow: hidden;
    &__pdlr {
        max-width: 1080px;
        margin: 0 auto;
    }
    &__options {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow: hidden;
        margin: 0 -10px;
        width: auto;
        // min-width: 600px;
        // max-width: 900px;
        // width: calc(100% - 100px);

        height: 440px;
    }
    &__option {
        position: relative;
        overflow: hidden;
        flex: 0 0 auto;
        min-width: 90px;
        width: auto;
        margin: 0 10px;
        //border:0px solid --defaultColor;

        background: $color-green;
        background-size: auto 120%;
        background-position: center;
        background-repeat: no-repeat;

        cursor: pointer;

        transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);


        &.active {
            flex-grow: 10000;
            transform: scale(1);
            max-width: 640px;
            border-radius: 20px;
            background-size: auto 100%;
            // background-size: cover;
        }

        &:not(.active) {
            flex-grow: 1;
            border-radius: 40px;
        }

        &-cont {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            position: absolute;
            left: 0; 
            bottom: 0;
            padding: 20px 0 20px 17px;
            z-index: 2;
            transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
            &::after {
                @include abs-center;
                width: calc(100% + 10px);
                height: calc(100% + 4px);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.60) 64.24%);
                backdrop-filter: blur(2px);
                content: '';
                z-index: -1;
            }
        }
        &.active &-cont {
            padding-left: 40px;
            padding-right: 40px;
        }
        
        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 auto;
            width: 56px;
            height: 56px;
            padding: 5px;
            border-radius: 100%;

            background-color: white;
        }
        &-info {
            position: relative;
            left: 20px;
            opacity: 0;
            transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
        }
        &.active &-info {
            left: 0;
            opacity: 1;
        }
        &-title {
            font-weight: bold;
        }
    }
     
    @media (max-width: $md-desktop) {
        &__options {
            height: 400px;
        }
        &__option {
            min-width: 80px;
            &-icon {
                width: 50px; height: 50px;
            }
            &.active &-cont {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__options {
            margin: 0 -5px;
        }
        &__option {
            margin: 0 5px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__options {
            margin: 0;
            overflow: initial;
            height: 250px;
        }
        &__option {
            margin: 0;
            flex: 0 0 auto !important;
            border-radius: 20px !important;
            max-width: initial !important;
            background-size: cover;
            &-cont {
                
                padding: 15px !important;
            }
            &-icon {
                width: 40px;
                height: 40px;
            }
            &-info {
                left: 0 !important;
                opacity: 1 !important;
            }
        }
    }

    
    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
    &__head {
        display: flex;
        align-items: flex-end;
        .section__intro {
            max-width: 400px;
        }
    }
    &__item {
        padding: 40px;
        border-radius: 10px;
        background: $bg-gray-light;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        &.item--smile {
            background: $color-light-green;
            padding-top: 20px;
            > img {
                margin: -10px auto 10px;
                max-width: 80%;
            }
        }
        &:hover {
            background: $color-light-green;
        }
        &-head {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 10px;
        }
        &-icon {
            margin-right: 4px;
            flex: 0 0 auto;
            width: 60px; height: 60px;
            border-radius: 10px;
            background: #fff;
            position: relative;
            transition: all 0.3s ease-in-out;
            img {
                position: absolute;
                right: -4px; bottom: -4px;
                max-height: 85%;
                max-width: 85%;
            }
        }
        &:hover &-icon {
            background: rgba(255, 255, 255, 0.5);
        }
        &-list {
            margin-bottom: auto;
            li + li {
                margin-top: 10px;
            }
            a {
                    
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $color-green;
                }
            }
        }
        &-mlink {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            color: $color-gray;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: $color-green;
            }
            &__arrow {
                width: 28px; height: 28px;
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;
                border-radius: 100%;
                transition: all 0.3s ease-in-out;
                .icon {
                    width: 45%;
                }
            }
            &:hover &__arrow {
                    border-color: $color-green;
            }
        }
        &-buttons {
            margin-top: 20px;
        }
    }
    @media (max-width: $md-desktop) {
        &__item {
            padding: 20px;
            &-icon {
                width: 42px; height: 42px;
            }
            &-mlink {
                justify-content: flex-end;
            }
            &-buttons {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__head {
            .section__intro {
                font-size: 18px;
                margin-top: 30px;
            }
        }
        &__items {
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
            max-width: 668px;
            margin: 0 auto;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__items {
            grid-gap: 20px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__head {
            .section__head {
                margin-bottom: 0;
            }
            .section__intro {
                font-size: 15px;
                margin-top: 20px;
            }
        }
        &__items {
            grid-template-columns: 1fr;
        }
        &__item {
           &.item--smile {
                text-align: center;
            } 
        }
    }
}

.dservices {
    &__cols {
        // display: flex;
        // gap: 20px;
        // align-items: flex-start;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0 20px;
    }
    &__photo {
        grid-row: span 2;
        &-wrap {
            width: 420px; height: 420px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            flex: 0 0 auto;
            img {
                @include photo-cover;
            }
        }
    }
    &__tags {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    &__tag {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
        border-radius: 50px;
        background: $bg-gray-light;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $color-light-green;
        }
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px; height: 24px;
            flex: 0 0 auto;
            img {
                max-height: 24px;
            }
        }
    }
    &__content {
        margin-top: 30px;
        padding: 40px;
        border-radius: 20px;
        background: $bg-gray-light;
        &.content--v-list, .content--v-list {
            p {
                max-width: 70%;
            }
            ul, ol {
                // column-count: 2;
                // column-gap: 40px;
            }
            li {
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
                break-inside: avoid-column;
            }
        }
        &.content--v-cols, .content--v-cols {
            column-count: 2;
            column-gap: 20px;
        }
        > * + * {
            margin-top: 30px;
        }
    }
    &__bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        &-text {
            max-width: 860px;
        }
    }

    @media (max-width: $md-desktop-md) {
        &__photo-wrap {
            width: 320px; height: 320px;
        }
        &__tags {
            margin-top: 20px;
        }
    }
    @media (max-width: $md-desktop) {
        &__content {
            padding: 20px;
        }
    }
    @media (max-width: $md-tablet) {
        &__photo {
            grid-row: initial;
            &-wrap {
                width: 280px; height: 280px;
            }
        }
        &__tags {
            grid-column: span 2;
        }
        &__tag {
            padding: 10px 20px;
            font-size: 15px;
            &-icon {
                width: 16px; height: 16px;
            }
        }
        &__content {
            p {
                max-width: 100% !important;
            }
            ul, ol {
                column-count: initial !important;
            }
            &.content--v-cols, .content--v-cols {
                column-count: initial !important;
            }
            > * + * {
                margin-top: 20px;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__cols {
            display: block;
        }
        &__photo {
            margin-top: 20px;
            max-width: 480px;
            &-wrap {
                width: 100%; height: auto;
                padding-bottom: 100%;
            }
        }
        &__tags {
            grid-column: initial;
            gap: 10px;
        }
        &__tag {
            padding: 10px;
            font-size: 13px;
            &-icon {
                width: 12px; height: 12px;
            }
        }
        &__content {
            margin: 40px -20px 0;
        }
        &__bottom {
            flex-direction: column;
            align-items: flex-start;
            &-buttons {
                width: 100%;
            }
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__content {
            margin-left: -1*$pd-container-smx;
            margin-right: -1*$pd-container-smx;
        }
    }
}


/**************************************************************
Наши специалисты
**************************************************************/
.team {
    overflow: hidden;
    &__mw {
        max-width: 1200px;
        margin: 0 auto;
    }
    &__header {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-end;
        gap: 20px;
    }
    &__head {
        padding-bottom: 20px;
    }
    &__hcard {
        width: 62%;
        flex: 0 0 auto;
    }
    &__carousel {
        margin: 0 -15px 0px;
        padding: 0 15px 15px;
        &.carousel--main {
            overflow: initial;
        }
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;
    }
    &__item {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: all 0.3s ease-in-out;
        &.item--lg {
            flex: 0 0 auto;
            flex-direction: row-reverse;
            align-items: flex-end;
            gap: 60px;
        }
        &:hover {
            box-shadow: 8px 8px 8px 0px rgba(170, 185, 183, 0.20);
            background: #fff;
        }
        &-photo {
            position: relative;
            padding-bottom: 100%;
            border-radius: 20px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            img {
                @include photo-cover
            }
        }
        &.item--lg &-photo {
            width: 48%;
            padding-bottom: 48%;
        }
        &:hover &-photo {
            border-radius: 0;
        }
        &-info {
            padding: 20px;
            flex: 1 0 0;
            transition: all 0.3s ease-in-out;
        }
        &-text {
            margin-top: 20px;
        }
        &-mlink {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            color: $color-gray;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: $color-green;
            }
            &__arrow {
                width: 28px; height: 28px;
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;
                border-radius: 100%;
                transition: all 0.3s ease-in-out;
                .icon {
                    width: 45%;
                }
            }
            &:hover &__arrow {
                    border-color: $color-green;
            }
        }
    }

    @media (max-width: $md-desktop) {
        &__hcard {
            width: 58%;
        }
        &__items {
            grid-gap: 30PX;
        }
        &__item {
            &.item--lg{
                gap: 10px;
            }
            &.item--lg &-photo {
                width: 51%;
                padding-bottom: 51%;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__mw {
            max-width: 630px;
        }
        &__header {
            margin-bottom: 20px;
        }
        &__head {
            padding: 0;
        }
        &__items {
            grid-template-columns: 1fr 1fr;
        }
        &__carousel {
            overflow: initial;
            .swiper-pagination {
                margin-top: 8px;
            }
            .swiper-slide {
                width: 300px !important;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__items {
            grid-template-columns: 1fr;
            grid-gap: 20px;
            max-width: 480px;
            margin: 0 auto;
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__carousel {
            .swiper-slide {
                width: 280px !important;
            }
        }
    }
}


.dteam {
    &__cols {
        display: flex;
        gap: 80px;
    }
    &__cont {
        margin-top: 50px;
    }
    &__photo {
        flex:  0 0 auto;
        width: 420px; height: 500px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        img {
            @include photo-cover;
        }
    }
    &__info {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    &__props {
        margin-top: 20px;
        max-width: 630px; width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px 20px;
        &-col {
            max-width: 250px;
        }
    }
    &__prop {
        & + & {
            margin-top: 10px;
        }
        &-label {
            margin-bottom: 5px;
            color: $color-gray;
        }
        li + li {
            margin-top: 5px;
        }
    }
    &__buttons {
        padding-top: 30px;
    }
    &__text {
        column-count: 2;
        column-gap: 20px;
    }

    @media (max-width: $md-desktop-md) {
        &__cols {
            gap: 30px;
        }
        &__info {
            
        }
        &__props {
            max-width: 500px;
            margin-top: 40px;
        }
        &__buttons {
            margin-top: auto;
        }
    }
    @media (max-width: $md-tablet) {
        &__cols {
            gap: 20px;
            align-items: flex-start;
        }
        &__photo {
            width: 350px; height: 420px;
        }
        &__props {
            margin-top: 20px;
            flex-direction: column;
            gap: 20px;
            &-col {
                max-width: 100%;
            }
            &-col.col--first {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;
            }
        }
        &__prop {
            margin: 0 !important;
            &-label {
                margin-bottom: 8px;
            }
            li + li {
                margin-top: 8px;
            }
        }
        &__buttons {
            padding-top: 20px;
        }
        &__cont {
            margin-top: 20px;
        }
        &__text {
            column-count: 1;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__cols {
            flex-direction: column;
            gap: 10px;
        }
        &__info {
            width: 100%;
        }
        &__props {
            margin-top: 10px;
            gap: 10px;
            &-col.col--first {
                grid-gap: 10px 20px;
            }
        }
        &__prop {
            margin: 0 !important;
            &-label {
                margin-bottom: 4px;
            }
            li + li {
                margin-top: 4px;
            }
        }
        &__info &__buttons {
            display: block !important;
            width: 100%;
        }
    }
    @media (max-width: $md-mobile) {
        &__photo {
            width: 300px; height: 356px;
        }
    }
}



/**************************************************************

**************************************************************/
.callback {
    &__inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    &__cols {
        display: flex;
        align-items: flex-start;
        gap: 20px;
    }
    &__photo {
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        padding-bottom: 100%;
        img {
            @include photo-cover;
        }
    }
    &__form {
        padding: 40px;
        position: relative;
        z-index: 2;
        &::after {
            @include abs-all;
            border-radius: 20px;
            background: $color-light-green;
            z-index: -1;
            content: '';
        }
        &.form--sm {
            width: 420px;
            flex: 0 0 auto;
            padding: 30px;
        }
    }
    &__bear {
        position: absolute;
        top: 30%; right: 20px;
        width: 250px;
        z-index: -1;
        transition: all 0.5s;
        &.active {
            transform: translate(60%,-30%);
        }
        svg {
            transform: rotate(40deg);
            width: 100%; height: auto;
        }
    }

    @media (max-width: $md-desktop-lg) {
        
        &__bear {
            top: 0;
            right: 30%;
            &.active {
                transform: translate(0, -40%);
            }
            svg {
                transform: initial;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__form {
            padding: 30px;
            .section__head {
                margin-bottom: 20px;
            }
            .section__intro {
                margin-top: 0;
            }
            .btn + .section__intro {
                margin-top: 20px;
            }
            &.form--sm {
                width: 380px;
                padding: 20px;
            }
        }
        &__bear {
            width: 200px;
            right: 20px;
        }
    }
    @media (max-width: $md-tablet) {
        &__form {
            padding: 20px;
            .section__head {
                margin-bottom: 10px;
            }
            .btn + .section__intro {
                margin-top: 10px;
            }
            &.form--sm {
                width: 350px;
                padding: 20px;
            }
        }
        &__bear {
            display: none;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__inner {
            grid-template-columns: 1fr;
            max-width: 480px;
            margin: 0 auto;
        }
        &__cols {
            flex-direction: column;
            align-items: center;
        }
        &__form {            
            &.form--sm {
                width: auto;
                max-width: 480px;
                margin: 0 -20px;
            }
        }
    }
    @media (max-width: $md-mobile) {
        .section__head {
            text-align: left;
        }
    }
    @media (max-width: $md-mobile-smx) {        
        &__form {
            &.form--sm {
                margin: 0 -1*$pd-container-smx;
            }
        }
    }
}




/**************************************************************

**************************************************************/
.ask {
    &__inner {
        padding: 50px 65px;
        background: $color-light-green;
        border-radius: 20px;
    }
    &__cols {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 12px;
    }
    .form__policy {
        color: $color-gray;
    }

    @media (max-width: $md-desktop) {
        &__inner {
            margin: 0 -20px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media (max-width: $md-tablet) {
        margin-bottom: 0 !important;
        .container {
            max-width: 100% !important;
            padding: 0 !important;
        }
        &__inner {
            margin: 0 !important;
            padding: 40px 20px;
            border-radius: 20px 20px 0 0;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__cols {
            display: block;
            max-width: 480px;
            margin: 0 auto;
        }
    }
}



/**************************************************************
Отзывы 
**************************************************************/
.reviews {
    position: relative;
    &__inner {
        background: $bg-gray-light;
        border-radius: 10px;
        padding: 90px 40px 40px;
        margin: 0 -40px;
        position: relative;
        @media (min-width: $md-desktop-lg+0.02px) {
            &.inner--wall {
                padding-left: 30px;
                padding-right: 30px;
                margin: 0 0 0 30px;
                border-radius: 10px;
            }
        }
        &::before {
            width: 100px; height: 80px;
            position: absolute;
            left: 0; top: 0;
            transform: translate(-30%, -25%);
            background: url(../images/icons/quotes.svg) no-repeat;
            background-size: 100%;
            content: '';
        }
    }
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        gap: 45px;
        height: 100%;
        border-radius: 10px;
        background: #FFF;
        padding: 20px;
        &-bottom {
            margin-top: auto;
            display: flex;
            align-items: center;
            gap: 15px;
        }
        &-photo {
            width: 24px; height: 24px;
            flex: 0 0 auto;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            img {
                @include photo-cover
            }
        }
        &-name {
            flex: 1 0 0;
        }
        &-date {
            font-size: 14px;
            color: $color-gray;
        }
    }
    
    &__masonry  {
        margin: 0 -20px -20px;
        &-item {
            width: 50%;
            padding: 0 10px 20px;
        }
    }
    @media (max-width: $md-desktop-lg) {
        &__inner {
            padding-left: 30px;
            padding-right: 30px;
            margin: 0 0 0 30px;
            border-radius: 20px;
        }
    }
    @media (max-width: $md-desktop-md) {
        &__inner {
            padding: 70px 30px 36px;
            margin: 0 0 0 20px;
            &::before {
                width: 70px; height: 55px;
                transform: initial;
                left: -18px; top: -11px;
            }
        }
        &__item {
            
        }
    }
    @media (max-width: $md-tablet) {
        &__inner {
            padding: 40px 20px;
            margin: 0;
            &::before {
                width: 54px; height: 43px;
            }
        }
        &__masonry {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__inner.inner--wall {
            background: none;
            padding: 0 !important;
            margin: 0 !important;
            &::before {
                display: none;
            }
            .reviews__item {
                background: $bg-gray-light;
            }
        }
        &__masonry {
            margin: 0 0 -20px;
            &-item {
                width: 100%;
                padding: 0 0 20px;
            }

        }
    }
    @media (max-width: $md-mobile-lg) {
        &__inner {
            margin: 0 -1*$pd-container;
            padding-bottom: 46px;
            &::before {
                width: 38px; height: 30px;
                left: 0; top: -15px;
            }
        }
        &__nav {
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__inner {
            margin: 0 -1*$pd-container-smx;
            padding-left: $pd-container-smx;
            padding-right: $pd-container-smx;
            padding-top: 30px;
        }
    }
}





/**************************************************************
Мы в соцсетях
**************************************************************/
.wesocial {
    &__photos {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
    &__head {
        grid-column: span 2;
    }
    &__intro {
        max-width: 380px;
    }
    &__buttons {
        margin-top: 20px;
    }
    &__insta {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        min-width: 330px;
        border-radius: 100px;
        background: $color-light-gray;
        padding: 10px 20px 10px 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $color-light-green;
        }
        &-icon {
            flex: 0 0 auto;
            width: 40px; height: 40px;
            background: $color-body;
            border-radius: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__photo {
        padding-bottom: 100%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        img {
            @include abs-all;
        }
    }
    &__smile {
        display: flex;
        align-items: flex-end;
    }
    @media (max-width: $md-tablet) {
        .section__head {
            margin-bottom: 10px;
        }
        &__photos {
            grid-gap: 10px;
        }
        &__buttons {
            margin-top: 16px;
        }
        &__insta {
            min-width: 300px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__photos {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        &__head {
            grid-column: span 3;
        }
    }
    @media (max-width: $md-mobile) {
        &__photos {
            grid-template-columns: repeat(2, 1fr);
        }
        &__head {
            grid-column: span 2;
        }
        &__insta {
            width: 100%;
        }
    }
}





/**************************************************************

**************************************************************/
.faq {
    &__items {
        max-width: 1000px;
        margin: 0 auto;
    }
    
    &__item {
        padding: 20px 40px;
        background: $color-light-gray;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        &.opened {
            background: $color-light-green;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        & + & {
            margin-top: 20px;
        }

        &-head {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 30px;
            padding-right: 140px;
            position: relative;
        }
        &-title {
            transition: all 0.3s ease-in-out;
            min-height: 60px;
            display: flex;
            align-items: center;
        }
        &.opened &-title {
            min-height: 0px;
        }

        &-plus {
            position: absolute;
            right: 0; top: 0;
            flex: 0 0 auto;
            width: 60px;
            height: 60px;
            background: #fff;
            border-radius: 100%;
            color: $color-violet;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;
            transform: rotate(90deg);
            .icon {
                width: 35%; height: 35%;
            }
        }

        &.opened &-plus {
            .icon {
                
                transform: scale(-1, 1);
            }
        }

        &-body {
            overflow: hidden;
            opacity: 0;
            max-height: 0px;
            transition: all 0.3s ease-in-out;
        }

        &.opened &-body {
            opacity: 1;
        }

        &-text {
            padding-right: 80px;
            padding-top: 20px;
        }
    }
    @media (max-width: $md-desktop-md) {
        &__item {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__item {
            padding: 20px !important;
            & + & {
                margin-top: 10px;
            }
            &-head {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
            }
            &-title {
                min-height: 30px;
                font-size: 15px;
            }
            &-plus {
                width: 30px; height: 30px;
                position: static;
            }
            &-text {
                padding: 16px 0 0;
            }
        }
    }
}




/**************************************************************

**************************************************************/
.ordform {
    &__inner {
        position: relative;
        border-radius: 10px;
        margin: 0 auto;
        width: 100%;
        max-width: 1600px;
        background: $color-green;
        color: #fff;
    }
    &__form {
        max-width: 490px;
        margin-left: 110px;
        padding: 60px 0;
        form {
            max-width: 430px;
        }
    }
    &__title {
        font-weight: normal;
    }
    &__img {
        position: absolute;
        right: 0; bottom: 0;
        width: 50%;
        height: 100%;
        &-cbg {
            @include abs-all;
            &::before {
                width: 600px; height: 600px;
                position: absolute;
                right: 0; bottom: -75px;
                border-radius: 601px;
                background: #CFFFF7;
                filter: blur(100px);
                opacity: 0.4;
                content: '';
            }
        }
        img {
            position: absolute;
            left: 0; bottom: 0;
            max-width: 110%;
            z-index: 2;
        }
        svg {
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%, 0%);
            max-width: 110%;
            height: 110%;
            z-index: 2;
            width: 100%;
        }
    }
    @media (max-width: $md-desktop-lg) {
        &__img {
            img {
                max-width: 100%;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__form {
            padding: 40px 0;
            margin-left: 20px;
            max-width: 430px;
        }
        &__head {
            
        }
        &__img {
            width: calc(100% - 430px);
            &-cbg::before {
                width: 460px; height: 460px;
                bottom: 50%;
                transform: translateY(50%);
            }
            img {
                right: 0;
                left: auto;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__form {
            padding: 40px 0 17px;
            margin: 0;
            max-width: 360px;
            form {
                max-width: 320px;
            }
        }
        &__title {
            font-size: 40px;
        }
        .section__intro {
            max-width: 320px;
        }
        &__img {
            right: -20px;
            height: calc(100% + 30px);
            // width: calc(100% - 280px);
            width: calc(100% - 360px);
            &-cbg::before {
                width: 340px; height: 340px;
            }
            &-wrap {
                @include abs-all;
                overflow: hidden;
            }
            img {
                right: auto;
                left: 0;
                max-width: 110%;
                max-height: 100%;
            }
            svg {
                width: 90%;
            }
        }

    }
    @media (max-width: $md-tablet-sm) {
        &__inner {
           border-radius: 10px 10px 0 0;
           overflow: hidden;
        }
        &__form {
            padding: 40px 0;
            max-width: 480px;
            form {
                max-width: 480px;
            }
        }
        &__title {
            font-size: 28px;
        }
        &__img {
            position: relative;
            right: auto;
            height: auto;
                margin: 0 -1*$pd-container;
                width: calc(100% + 2*$pd-container);
            display: flex;
            justify-content: center;
            &-wrap {
                overflow: initial;
                max-width: 400px;
                margin: 0 auto;
                position: static;
            }
            img {
                width: 110%;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
            svg {
                position: relative;
                // width: 80%;
                // margin-bottom: -40%;
                transform: translateX(-50%);
                width: 60%;
            }
        }
    }
    @media (max-width: $md-mobile-smx) {
        
        &__img {
                margin: 0 -1*$pd-container;
                width: calc(100% + 2*$pd-container);
                &-wrap {
                }
        }
    }
}





/**************************************************************

**************************************************************/
.about {
    &__photo {
        margin: 0 -50px 50px;
        padding-bottom: 48%;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        img {
            @include photo-cover;
        }
    }
    &__cols {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    &__head {
        
    }
    &__cont {
        padding-left: 60px;
        padding-top: 15px;
    }
    &__props {
        border-radius: 20px;
        padding: 40px;
        background: $bg-gray-light;
        display: flex;
    }
    &__prop {
        padding: 0 20px;
        min-width: 180px;
        & + & {
            border-left: 1px solid #BFC2D3;
        }
        &-numb {
            font-size: 48px;
            font-family: $font-pangram;
            font-weight: 300;
            line-height: 1.3;        
        }
        &-text {
            margin-top: 6px;
        }
    }

    @media (max-width: $md-desktop-md) {
        &__photo {
            margin: 0 0 20px;
            padding-bottom: 45%;
        }
        &__prop {
            min-width: 130px;
            padding: 0 10px;
            &-numb {
                font-size: 36px;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__cont {
            padding: 5px 0 0;
        }
    }
    @media (max-width: $md-tablet) {
        &__cols {
            grid-template-columns: 1fr;
        }
        &__props {
            max-width: 480px;
            padding: 20px;
        }
        &__prop {
            min-width: 130px;
            padding: 0 10px;
            &-numb {
                font-size: 28px;
            }
            &-text {
                font-size: 15px;
            }
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__photo {
            border-radius: 10px;
        }
        &__props {
            background: none;
            flex-direction: column;
            padding: 0;
            gap: 10px;
        }
        &__prop {
            border-radius: 20px;
            padding: 10px 20px 10px 30px;
            background: $bg-gray-light;
            border: none !important;
            display: flex;
            align-items: center;
            gap: 10px;
            &-numb {
                transform: translateY(-3px);
            }
        }
    }
}






/**************************************************************

**************************************************************/
.photos {
    &__items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
    &__item {
        border-radius: 10px;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;
        grid-auto-flow: dense;
        &.item--h-2 {
            grid-row: span 2;
        }
        &.item--w-2 {
            grid-column: span 2;
            padding-bottom: 50%;
        }
        &.item--w-2.item--h-2 {
            padding-bottom: 100%;
        }
        img {
            @include photo-cover;
        }
    }
    @media (max-width: $md-desktop) {
        &__items {
            grid-gap: 10px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__items {
            grid-template-columns: 1fr 1fr;
        }
        &__item {
            &.item--last-mob {
                order: 10;
            }
        }
    }
}





/**************************************************************

**************************************************************/
.store {
    &__cinfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 30px;
        margin-bottom: 30px;
    }
    &__text {
        max-width: 860px;
    }
    &__action {
        padding: 0 40px 20px 0;
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
    &__item {
        position: relative;
        padding: 20px 20px 40px;
        border-radius: 20px;
        background: $bg-gray-light;
        text-align: center;
        transition: all 0.3s ease-in-out;
        @media (hover: hover) {
            &:hover {
                background: $color-light-green;
                
            }
            
        }
        &-photo {
            padding-bottom: 100%;
            border-radius: 10px;
            background: #fff;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            img {
                @include abs-center;
                max-height: 100%;
            }
        }
        &-info {
            position: relative;
        }
        &-buttons {
            position: absolute;
            top: 100%;
            left: -20px; right: -20px;
            padding: 20px;
            opacity: 0; visibility: hidden;
            transition: all 0.3s ease-in-out;
            background: $color-light-green;
            border-radius: 0 0 20px 20px;
        }
        &:hover &-buttons {
            opacity: 1; visibility: visible;
        }
    }

    @media (max-width: $md-desktop) {
        &__text {
            max-width: 660px;
        }
        &__action {
            padding: 0;
        }
        &__items {
            grid-gap: 17px;
        }
        &__item {
            padding: 15px 15px 30px;
            &-photo {
                margin-bottom: 15px;
            }
            &-title {
                font-size: 14px;
            }
            &-buttons {
                padding: 15px;
                left: -15px; right: -15px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__items {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
        &__scroll &__item:nth-child(4) {
            display: none;
        }
        &__item {
            padding: 15px;
            display: flex;
            flex-direction: column;
            &-info {
                flex: 1 0 0;
                display: flex;
                flex-direction: column;
            }
            &-buttons {
                background: none !important;
                opacity: 1; visibility: visible;
                position: static;
                padding: 15px 0 0;
                margin-top: auto;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        overflow: hidden;
        &__cinfo {
            margin-bottom: 20px;
        }
        &__scroll {
            display: flex;
            gap: 20px;
            overflow: auto;
            margin: 0 -1*$pd-container;
            padding: 0 $pd-container;
        }  
        &__scroll &__item {
            flex: 0 0 auto;
            width: 230px;
        }
        &__item {
            display: flex !important;
        }
    }
}





/**************************************************************

**************************************************************/
.prices {
    &__mw {
        max-width: 1200px;
        margin: 0 auto;
    }
    &__item {
        & + & {
            margin-top: 20px;
        }

        &-head {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            position: relative;
            padding: 20px 40px;
            background: $color-light-gray;
            border-radius: 20px;
            transition: all 0.3s ease-in-out;
        }
        &.opened &-head {
            background: $color-light-green;
        }

        &-plus {
            flex: 0 0 auto;
            width: 60px;
            height: 60px;
            // background: #fff;
            border-radius: 100%;
            color: $color-body;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;
            transform: rotate(90deg);
            .icon {
                width: 35%; height: 35%;
            }
        }

        &.opened &-plus {
            background: #fff;
            .icon {
                transform: scale(-1, 1);
            }
        }

        &-body {
            overflow: hidden;
            opacity: 0;
            max-height: 0px;
            transition: all 0.3s ease-in-out;
        }

        &.opened &-body {
            opacity: 1;
        }

        &-content {
            padding-top: 20px;
        }
    }
    &__row {
        border-radius: 20px;
        background: $bg-gray-light;
        padding: 12px 30px;
        display: flex;
        justify-content: space-between;
        gap: 40px;
        & + & {
            margin-top: 15px;
        }
        &-val {
            text-align: right;
        }
    }
    &__rhead {
        padding-top: 22px;
        padding-bottom: 22px;
        background: $color-light-green;
    }
    @media (max-width: $md-desktop-md) {
        &__item {
            & + & {
                margin-top: 10px;
            }
            &-head {
                padding: 10px 10px 10px 40px;
            }
            &-plus {
                width: 40px; height: 40px;
                background: #fff;
            }
            &-content {
                padding-top: 10px;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__row {
            & + & {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__row {
            padding: 12px;
            gap: 20px;
        }
    
        &__item {
            & + & {
                margin-top: 20px;
            }
            &-head {
                min-height: 60px;
                padding: 12px;
            }
            &-plus {
                width: 24px; height: 24px;
            }
        }
    }
}





/**************************************************************

**************************************************************/
.warning {
    &__inner {
        max-width: 1400px;
        margin: 0 auto;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        background: $bg-gray-light;
        display: flex;
        
    }
    &__photo {
        width: 53%;
        flex: 0 0 auto;
        min-height: 565px;
        position: relative;
        &::before {
            @include abs-all;
            background: linear-gradient(270deg, $bg-gray-light 0.13%, rgba(217, 217, 217, 0) 30%);
            z-index: 2;
            content: ''
        }
        img {
            @include photo-cover;
        }
    }
    &__info {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        padding: 60px 40px 40px 84px;
        flex: 1 0 0;
    }
    &__text {
        margin-top: 20px;
        > * + * {
            margin-top: 10px;
        }
    }
    &__phone {
        font-weight: 300;
        font-size: 16px;
        line-height: normal;
    }
    &__buttons {
        margin-top: 45px;
    }

    @media (max-width: $md-desktop-md) {
        &__inner {
            border-radius: 20px;
        }
    }
    @media (max-width: $md-desktop) {
        &__photo {
            min-height: 545px;
            width: 50%;
        }
        &__info {
            padding-left: 30px;
        }
    }
    @media (max-width: $md-tablet) {
        &__photo {
            min-height: 400px;
            width: 50%;
        }
        &__info {
            padding: 30px 20px 20px;
        }
        &__text {
            margin-top: 10px;
        }
        &__buttons {
            margin-top: 20px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__inner {
            flex-direction: column;
        }
        &__photo {
            width: 100%;
            height: 454px;
            &::before {
                background: linear-gradient(0deg, $bg-gray-light 0.13%, rgba(217, 217, 217, 0) 30%);
            }
        }
        &__info {
            padding: 20px;
        }
    }
}





/**************************************************************

**************************************************************/
.contacts {
    &__cols {
        display: flex;
        gap: 20px;
    }
    &__info {
        flex:  0 0 auto;
        width: 41%;
        border-radius: 20px;
        background: $bg-gray-light;
        padding: 40px;
    }
    &__item {
        & + & {
            margin-top: 24px;
        }
        &-head {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &-icon {
            width: 40px; height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 70%;
                max-height: 70%;
            }
        }
        &-val {
            padding-left: 50px;
            & + & {
                margin-top: 10px;
            }
            a {
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    &__map {
        flex: 1 0 0;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 30%;
        > * {
            @include abs-all
        }
    }

    @media (max-width: $md-desktop) {
        &__info {
            padding: 30px;
        }
        &__item {
            &-head {
                margin-bottom: 3px;
            }
            &-icon {
                width: 36px; height: 36px;
            }
        }
    }
    @media (max-width: $md-tablet) {
        &__cols {
            flex-direction: column;
        }
        &__info {
            padding: 20px;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
        &__item {
            margin: 0 !important;
            &-val {
                padding-left: 46px;
            }
        }
        &__map {
            padding-bottom: 68%;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__cols {
            margin: 0 -20px;
        }
        &__info {
            grid-template-columns: 1fr;
        }
        &__map {
            padding-bottom: 90%;
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__cols {
            margin: 0 -1*$pd-container-smx;
        }
    }
}





/**************************************************************

**************************************************************/





/**************************************************************

**************************************************************/
.error404 {
    .container {
        position: static;
    }
    &__img {
        position: absolute;
        bottom: 75px; top: 0; 
        left: 20px; right: 20px;
        border-radius: 20px;
        overflow: hidden;
        z-index: -1;
        &::before {
            @include abs-all;
            background: rgba(217, 217, 217, 0.10);
            content: '';
            z-index: 2;
        }
        img {
            @include photo-cover;
        
        }
    }
    &__inner {
        margin: 0 auto;
        max-width: 1540px;
        min-height: calc(var(--vh) * 100 - var(--hHeader));
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;    
        padding-bottom: 105px;
        z-index: 1;
    }
    &__numb {
        font-size: 200px;
        font-weight: 800;
        line-height: 1;
    }
    &__text {
        max-width: 750px;
        margin: 0 auto;
        font-size: 24px;
    }
    &__buttons {
        margin-top: 20px;
    }

    @media (max-width: $md-desktop-lg) {
        &__inner {
            padding-bottom: 40px;
        }
        &__img {
            bottom: 20px;
        }
        &__numb {
            font-size: 120px;
        }
        &__text {
            max-width: 500px;
            font-size: 18px;
        }
    }
    @media (max-width: $md-tablet) {
        
        &__inner {
            padding-bottom: 105px;
        }
        &__img {
            bottom: 75px;
        }
    }
    @media (max-width: $md-mobile-lg) {
        // position: relative;
        &__inner {
            min-height: 1px;
            padding-bottom: 20px;
            padding-top: 20px;
        }
        &__img {
            position: relative;
            left: auto; right: auto;
            bottom: auto; top: auto;
            padding-bottom: 57%;
            margin-bottom: 30px;
        }
        &__numb {
            font-size: 80px;
        }
        &__text {
           margin-top: 25px;
        }
        &__buttons {
            margin-top: 10px;
        }
    }
}






/**************************************************************

**************************************************************/
.sert {
    &__inner {
        margin-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
    }
    &__head {
        flex: 0 0 auto;
        width: 33%;
    }
    &__carousel {
        flex: 1 0 0;
        min-width: 1px;
    }
    &__item {
        &-wrap {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-height: 300px;
            }
        }
    }
    @media (max-width: $md-desktop) {
        &__inner {
            margin-left: 0;
        }
    }
    @media (max-width: $md-tablet) {
        &__inner {
            display: block;
        }
        &__head {
            margin-bottom: 20px;
            width: 100%;
        }
    }
    @media (max-width: $md-mobile-lg) {
        &__carousel {
            margin: 0 -1*$pd-container;
            padding: 0 $pd-container;
        }
    }
}







/**************************************************************

**************************************************************/








/**************************************************************

**************************************************************/



