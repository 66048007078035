.section {
    &__head {
        margin-bottom: 30px;
        &.hcols,
        &-cols {
            display: flex;
            justify-content: space-between;
            gap: 30px;
        }
        &-cols {
            margin-top: 30px;
        }
        &.al-center {
            align-items: center;
        }
        &.al-end {
            align-items: flex-end;
        }
        &.mb--sm {
            margin-bottom: 20px;
        }
        &.mb0 {
            margin-bottom: 0 !important;
        }
        .swiper-nav {
            margin-top: 0;
        }
        &.hcols .swiper-nav {
            padding-top: 5px;
        }
    }
    &__title {
        font-size: 48px;
        font-family: $font-pangram;
        font-weight: 300;
        line-height: 1.3;
        &.fs--sm {
            
        }
    }
    &__intro {
        margin-top: 30px;
        &.mw {
            max-width: 640px;
        }
        &.mt--lg {
            margin-top: 50px;
        }
        &.mt--md {
            margin-top: 20px;
        }
        &.mt--sm {
            margin-top: 10px;
        }
        &.mb--sm {
            margin-bottom: 8px;
        }
        &:first-child {
            margin-top: 0 !important;
        }
        .text--center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__buttons {
        margin-top: 50px;
        text-align: center;
        &.mt--md {
            margin-top: 30px;
        }
    }
    &__first {
        margin-top: 0 !important;
    }
    &__mg {
        margin: 150px 0;
        &--md {
          margin: 100px 0;
        }
        &--sm {
           margin: 50px 0;
        }
    }
    &__mgb {
        margin-bottom: 150px;
        &--md {
            margin-bottom: 100px;
        }
        &--sm {
          margin-bottom: 50px;
        }
    }
    &__pd {
        padding: 130px 0;
    }
    &__pdt {
        padding-top: 70px;
    }
    &__bg {
        background: $bg-gray-light;
        padding: 90px 0 90px;
    }
    &__tab {
        display:none;
        &.show {
            display:block;
        }
    }
    
    @media (max-width: $md-desktop-md) {
        &__title {
            font-size: 40px;
        }
        &__mg {
            margin: 100px 0;
        } 
        &__bg {
            padding: 100px 0;
        }
    }
    @media (max-width: $md-desktop) {
        &__title {
            font-size: 36px;
        }
        &__intro {
                
            &.mt--lg {
                margin-top: 50px;
            }
        }
    }
    @media (max-width: $md-tablet) {
       
        &__head {
            margin-bottom: 20px;
            &.hcols {
                // flex-direction: column;
                // gap: 20px;
            }
            &-cols {
                margin-top: 20px;
            }
        }
        &__title {
            font-size: 28px;
            
        }
        &__intro {
            margin-top: 20px;    
            &.mw {
                max-width: 540px;
            }
        }
        &__mg {
            margin: 80px 0;
            &--md {
              margin: 80px 0;
            }
            &--sm {
               margin: 50px 0;
            }
        } 
        &__bg {
            padding: 40px 0;
        }
        
        &__buttons {
           margin-top: 35px;
        }
    }
    @media (max-width: $md-tablet-sm) {
     
        &__head {
           text-align: left !important;
        }
        &__title {
            * {
                color: $color-body !important;
            }
            
        }
        &__mg {
            margin: 40px 0;
            &--md {
              margin: 40px 0;
            }
            &--sm {
               margin: 40px 0;
            }
        } 
        &__buttons {
            margin-top: 20px;
        }
    }
    @media (max-width: $md-mobile) {
          
    }
}

.title {
    font-size: 24px;
    line-height: 1.3;
    @media (max-width: $md-desktop-md) {
        font-size: 18px;   
    }
    @media (max-width: $md-tablet-sm) {
        font-size: 21px;   
    }
}
.text {
    margin-top: 10px;
    @media (max-width: $md-desktop) {
        
    }
}

.text--center {
    text-align: center;
}
.text--white {
    color: #ffff;
}
.text--violet {
    color: $color-violet;
}