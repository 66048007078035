
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Regular.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('../fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('../fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Light.eot');
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('../fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Semibold.eot');
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('../fonts/Gilroy/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-Semibold.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Medium.eot');
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('../fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-RegularItalic.eot');
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url('../fonts/Gilroy/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-RegularItalic.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-RegularItalic.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-BoldItalic.eot');
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url('../fonts/Gilroy/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy/Gilroy-BoldItalic.woff2') format('woff2'),
        url('../fonts/Gilroy/Gilroy-BoldItalic.woff') format('woff'),
        url('../fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'PP Pangram Sans';
    src: url('../fonts/PPPangramSans/PPPangramSans-Light.woff2') format('woff2'),
        url('../fonts/PPPangramSans/PPPangramSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PP Pangram Sans';
    src: url('../fonts/PPPangramSans/PPPangramSans-CompactRegular.woff2') format('woff2'),
        url('../fonts/PPPangramSans/PPPangramSans-CompactRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}