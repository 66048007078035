.icon {
    stroke: currentColor;
    fill: none;
    color: inherit;
    font-size: inherit;
    transition: all 0.3s ease-in-out;
    flex: 0 0 auto;
    &--fill {
        fill: currentColor;
        stroke: initial;
    }
    path {
        stroke: currentColor;
        color: inherit;
        font-size: inherit;
        transition: all 0.3s ease-in-out;
    }
}


.social {
    &__items {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    &__item {
        width: 40px; height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        color: #fff !important;
        background: #383B4B;
        border-radius: 100px;
        &:hover {
            background: $color-green;
        }
        .icon {
            width: 100%; height: 100%;
        }
    }
    @media (max-width: $md-tablet) {
        &__items {
            gap: 8px;
        }
        &__item {
            width: 30px; height: 30px;
        }
    }
}   




// .rating {
// 	margin: 0 auto;
// 	display: inline-flex;
//     flex-wrap: wrap;
//     align-items: flex-end;
//     margin: 0 -2px;
//     span {
//         position: relative;
//         top: -1px;
//         width: 19px; height: 18px;
//         margin: 0 2px;
//         background: url(../images/icons/star.svg) no-repeat;
//         background-size: cover !important;
//         &.active {
//             background: url(../images/icons/star-active.svg) no-repeat;
//         }
//     }
// }




.link {

    font-size: 20px;
    line-height: lh(27, 20);
    font-weight: 700;
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    .icon {
        width: 20px; height: 20px;
        margin-left: 8px;
    }
    &:hover {
       
    }
    &__more {
        .close_t {
            display:none
        }
        &.opened .open_t {
            display:none
        }
        &.opened .close_t {
            display:block
        }
    }
    @media (max-width: $md-desktop) {
        font-size: 16px
    }
}

// .content__toggle {
//     > * {
//         display: none;
//     }
//     & > :nth-child(1),
//     & > :nth-child(2) {
//         display: block;
//     }
//     &.opened > * {
//         display: block !important;
//     }
//     &--mobile {
//         > * {
//             display: block;
//         }
//     }
//     @media (max-width: $md-tablet-sm) {
//         > * {
//             display: none !important;
//         }
//         & > :nth-child(1) {
//             display: block !important;
//         }
//     }
// }



/**************************************************************
.breadcrumbs
**************************************************************/
.breadcrumbs {
    margin: 10px 0 50px;

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0;
    }

    &__item {
        font-size: 18px;
        display: flex;
        align-items: center;

        &:not(:last-child):after {
            margin: 0 10px;
            color: $color-gray;
            content: '/';
        }

    }

    &__link {
        color: $color-gray;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $color-green;
        }
    }

    @media (max-width: $md-desktop) {
        &__item {
            font-size: 15px;
        }
    }
    @media (max-width: $md-tablet) {
        margin: 0 0 20px;
    }
    @media (max-width: $md-tablet-sm) {
       margin-top: 8px;

        &__item {
         
        }
    }
}


/**************************************************************
.pagination
**************************************************************/
.pagination {

    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .page-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
    }
    .page-item.disabled {
        // display: none;
    }
    .page-link {
        background: none;
        width: 24px;
        height: 24px;
        color: $color-body;
        font-size: 14px;
        font-weight: 600;
        padding: 0 5px;
        border-radius: 4px;
        background: $bg-gray-light;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #fff !important;
            background: $color-green;
        }
        .icon {
            width: 100%;
        }
    }
    .active .page-link {
        background: $color-green;
        border-color: $color-green;
        color: #fff !important;
    }
    .page-item.disabled {
        // display: none;
    }
    .page-item.disabled .page-link {
        // cursor: default;
        // background: none;
       
    }
    .page-next .page-link, .page-prev .page-link {
        color: $color-violet;
        padding: 0 !important;
    }
    .page-next .icon {
        // transform: translateX(2px);
    }
    .page-prev .icon {
        // transform: translateX(-1px);
    }
    .page-last, .page-first {
        display: none;
    }

    @media (max-width: $md-tablet-sm) {
        justify-content: center; 
    }
    @media (max-width: $md-mobile-lg) {
        margin-top: 30px;
    }
}


/**************************************************************
.smile
**************************************************************/
@keyframes a0_do { 0% { stroke-dashoffset: 553.95px; } 42.28% { stroke-dashoffset: 0px; } 100% { stroke-dashoffset: 0px; } }
@keyframes a1_t { 0% { transform: translate(193.35px,11.99px) rotate(180deg) translate(179.29px,-2.07px); } 40.2% { transform: translate(193.35px,11.99px) rotate(0deg) translate(179.29px,-2.07px); } 46.36% { transform: translate(193.35px,-21.01px) rotate(0deg) translate(179.29px,-2.07px); } 100% { transform: translate(193.35px,-21.01px) rotate(0deg) translate(179.29px,-2.07px); } }