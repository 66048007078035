
/**************************************************************
popup
**************************************************************/
.popup {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    overflow: auto;
    opacity: 0; visibility: hidden;
    z-index: 9999;
    transition: all 0.3s ease-in-out;
    &.opened {
        opacity: 1; visibility: visible;
    }
    &__content {
        width: 100%;
        min-height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 15px;
        position: relative;
        z-index: 1;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(56, 61, 72, 0.60);
        backdrop-filter: blur(2px);
    }
    &__container {
        position: relative;
        width: 100%; max-width: 660px;
        padding: 50px;
        background: $color-light-green;
        // overflow: hidden;
        border-radius: 20px;
        // display: flex;
        // align-items: center;
        .popup--lg & {
            max-width: 1248px;
        }
    }
    &__close {
        position: absolute;
        right: 30px; top: 30px;
        width: 28px; height: 28px;
        cursor: pointer;
        z-index: 99;
        color: $color-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            color: $color-body;
        }
        .icon {
            width: 50%; height: 50%;
        }
    }
    &__head {
        margin-bottom: 20px;
        max-width: 560px;
        &.mb--md {
            margin-bottom: 30px;
        }
        &.text--center {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__title {
        font-size: 48px;
        font-family: $font-pangram;
        font-weight: 300;
        line-height: 1.3;
    }
    &__intro {
        margin-top: 30px;
    }
 
    &__iconthank {
        max-width: 390px;
        margin: -55px auto 0 ;
        svg {
            width: 100%;
            height: auto;
        }
    }
    &-thank &__container {
        padding-top: 90px;
        padding-bottom: 35px;
        text-align: center;
    }

    
    @media (max-width: $md-desktop) {
        &__title {
            font-size: 36px;
        }
    }
    @media (max-width: $md-tablet) {
        &__container {
            padding: 30px;
            max-width: 480px !important;
        }
        &__close {
            top: 20px; right: 20px;
        }
        &__title {
            font-size: 28px;
        }
        &__intro {
            margin-top: 20px;
        }
        &__iconthank {
            max-width: 300px;
            margin-top: -40px;
        }
        &-thank &__container {
            padding-top: 50px;
        }
    }
    @media (max-width: $md-mobile) {
        &__container {
            padding: 24px 20px 30px;
        }
        &__close {
            top: 10px; right: 10px;
        }
        &__intro {
            margin-top: 10px;
        }
    }
    
}