/**************************************************************
header
**************************************************************/
.header {
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    z-index: 999;
    .home & {
        animation: header-home 1.5s ease-in-out;
    }
    @keyframes header-home {
        0% { opacity: 0; transform: translateY(-100%); }
        to { opacity: 1; transform: translateY(0); }
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        height: $header-h;
        padding: 10px 0;
    }
    &__burger {
        display: none;
    }
    &__call {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        z-index: 2;
        &-open {
            display: none;
        }
        &-icon {
            width: 40px; height: 40px;
            border-radius: 100px;
            background: $color-yellow;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 16px;
                max-height: 16px;
            }
        }
        &-info {
            position: relative;
        }
        &-t {
            font-size: 14px;
        }
        &-phone {
            color: $color-violet;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            gap: 3px;
            .icon {
                width: 24px; height: 24px;
            }
        }
        &-mphones {
            position: absolute;
            right: -10px; top: -10px;
            z-index: -1;
            width: calc(100% + 70px);
            padding: 60px 10px 16px 60px;
            background: #fff;
            box-shadow: 4px 4px 8px 0px rgba(65, 58, 125, 0.05);
            border-radius: 20px;
            transition: all 0.3s ease-in-out;
            opacity: 0; visibility: hidden;
        }
        &:hover &-mphones {
            opacity: 1; visibility: visible;
        }
        &-mphones &-phone {
            margin-top: 4px;
        }
    }

    @media (max-width: $md-desktop) {
        &__logo {
            max-width: 230px;
        }
    }
    @media (max-width: $md-tablet) {
        &__inner {
            height: $header-h-t;
        }
        &__logo {
            max-width: 230px;
        }
        &__menu {
            // display: none
        }
        &__call {
            // display: none
            position: relative;
            margin-left: auto;
            margin-right: 20px;
            &-open {
                @include abs-all;
                z-index: 5;
                border: none;
                background: none;
                outline: none;
                display: block;
            }
            &-info {
                position: absolute;
                right: 0; top: 0;
                background: #fff;
                box-shadow: 4px 4px 8px 0px rgba(65, 58, 125, 0.05);
                border-radius: 20px;
                padding: 20px;
                transition: all 0.3s ease-in-out;
                opacity: 0; visibility: hidden;
                z-index: 10;
                &.opened {  
                    opacity: 1; visibility: visible;
                }
            }
            &-t {
                color: $color-green;
                white-space: nowrap;
                margin-bottom: 4px;
            }
            &-phone {
                font-weight: 400;
                white-space: nowrap;
                .icon {
                    display: none;
                }
            }
            &-mphones {
                opacity: 1; visibility: visible;
                position: static;
                padding: 0;
                background: none;
                box-shadow: none;
                width: auto;
                
            }
        }
        &__menu {
             display: none
        }
        &__burger {
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border: none;
            background: none;
            cursor: pointer;
            outline: none;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__inner {
            height: $header-h-m;
        }
        &__logo {
            max-width: 170px;
        }
        &__call {
             display: none
        }
    }
}



@import "components/menu";
@import "components/navfix";