.navfix {

    position: fixed;
    z-index: 9999;
    left: 0; top: 0;
    width: 100%; height: calc(var(--vh) * 100);
    z-index: -100;
    opacity: 0; visibility: hidden;
    transition:  all 0.3s ease-in-out;
    overflow: auto;
    margin: 0; 
    color: #fff;
    &.opened {
        z-index: 99999;
        opacity: 1; visibility: visible;
    }
    &__bg {
        position: fixed;
        z-index: 9999;
        left: 0; top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.50);
        backdrop-filter: blur(7.5px);
        z-index: 0;
    }
    &__box {
        width: 100%;
        max-width: 400px; height: calc(var(--vh) * 100);
        padding: 20px 20px 20px 30px;
        position: absolute;
        right: 0; top: 0;
        z-index: 5;
        border-radius: 20px 0px 0px 20px;
        background:  #FFF;
        box-shadow: -4px 4px 8px 0px rgba(65, 58, 125, 0.05);
        backdrop-filter: blur(10px);
        overflow: auto; 
        transition:  transform 0.3s ease-in-out;
        transform: translateX(100%);
    }
    &.opened &__box {
        transform: translateX(0%);
    }
    &__head {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // background: $color-blue;
        // position: sticky;
        // top: 0;
        z-index: 5;
        margin-bottom: 20px;
    }
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px; height: 16px;
        margin-right: 10px;
        border: none;
        background: none;
        cursor: pointer;
        outline: none;
    }
 
    
    &__menu {
        li {
            position: relative;
            z-index: 1;
            & + li {
                // margin-top: 10px;
            }
            a {
                font-size: 18px; color: $color-body;
                position: relative;
                display: block;
                padding: 10px;
            }
            &.active a {
                
                // border-radius: 10px;
                // background: $bg-gray-light;
            }
        }
        ul ul {
            overflow: hidden;
            opacity: 0;
            max-height: 0px;
            transition: all 0.3s ease-in-out;
            &.opened {
                opacity: 1;
            }
            li {
                padding-left: 20px;
            }
            li + li {
                margin-top: 12px;
            }
            a {
                padding: 0;
            }
            ul {
                li {
                    padding-left: 10px;
                    margin-top: 12px;
                }
                a {
                    font-size: 15px;
                }
            }
        }
        &-toggle {
            background: none;
            border: none;
            outline: none;
            position: absolute;
            top: 5px; right: 10px;
            z-index: 5;
            color: $color-gray;
            width: 30px; height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            &.active {
                transform: scale(1, -1);
            }
            .icon {
                width: 20px; height: 20px;
            }
            ul ul & {
                top: 0;
                height: 27px;
            }
        }
    }

    &__phone {
        color: $color-violet;
        font-size: 18px;
        margin-top: 10px;
        display: block;
    }
    &__call {
        padding-left: 10px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        &-link {
            color: $color-green;
            display: inline-block;
            margin-top: 10px;
            font-size: 16px;
        }
    }

    @media (max-width: $md-mobile-lg) {
        &__box {
            max-width: 330px;
        }    
        &__menu {
            li a {
                font-size: 16px;
            }
        }
    }
    
}