
/**************************************************************
footer
**************************************************************/
.footer {
    position: relative;
    z-index: 9;
    padding: 36px 0 20px;
    background: $color-light-green;
    font-size: 16px;
    color: #383B4B;
    &__head {
        margin-bottom: 20px;
    }
    &__intro {
        margin-top: 10px;
        font-size: 14px;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        gap: 65px;
    }
    &__col {        
        li {
            & + li {
                margin-top: 10px;
            }
            a {
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
    &__ctitle {
        margin-bottom: 10px;
        font-weight: 600;
    }
    &__colnav {
        
    }
    &__social {
        margin-top: 54px;
    }
    &__nav {
        li a {
            font-weight: 600;
        }
        li .active {
            font-weight: 600;
            color: #20ad96;
        }
    }
    &__menu {
        flex: 1 0 0;
        ul {
            margin-bottom: 8px;
        }
    }
    &__contacts {
        flex: 0 0 auto;
        width: 275px;
        &-linkmap {
            font-size: 12px;
            font-weight: 300;
            display: inline-block;
            border-bottom: 1px solid inherit;
        }
        ul {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__bottom {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        font-size: 10px;
        color: rgba(9, 10, 12, 0.5);
        a {
            transition: all 0.3s ease-in-out;
            &:hover {
                color: $color-body;
            }
        }
    }
    &__sname {
        text-transform: uppercase;
        flex: 0 0 auto;
        width: 275px;
    }

    @media (max-width: $md-desktop-md) {
        &__inner {
            gap: 30px;
        }
    }
    @media (max-width: $md-desktop) {
        font-size: 14px;
        &__contacts {
            width: 230px;
        }
        &__sname {
            width: 230px;
        }
    }
    @media (max-width: $md-tablet) {
        font-size: 12px;
        &__head {
            margin-bottom: 0;
        }
        &__intro {
            font-size: 12px;
            
        }
        &__inner {
            flex-wrap: wrap;
            gap: 20px;
        }
        &__colnav {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;   
        }
        &__nav {
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
            }
            li {
                margin: 0 !important;
            }
        }
        &__social {
            margin: 0;
            width: 165px;
        }
        &__contacts {
            width: 165px;
        }
        &__bottom {
            font-size: 9px;
        }
        &__sname {
            width: 165px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        &__head {
            margin-bottom: 10px;
        }
        &__col {
            li + li {
                margin-top: 4px;
            }
        }
        &__ctitle {
            margin-bottom: 10px;
            font-size: 13px;
        }
        &__nav {
            width: 100%;
            max-width: 480px;
            ul {
                display: block;
            }
            li {
                position: relative;
                z-index: 1;
                & + li {
                    // margin-top: 10px;
                }
                a {
                    font-weight: normal;
                    position: relative;
                    display: block;
                    padding: 10px 0;
                }
                &.active a {
                    
                    // border-radius: 10px;
                    // background: $bg-gray-light;
                }
            }
            ul ul {
                overflow: hidden;
                opacity: 0;
                max-height: 0px;
                transition: all 0.3s ease-in-out;
                &.opened {
                    opacity: 1;
                }
                li {
                    padding-left: 10px;
                }
                li + li {
                    margin-top: 12px !important;
                }
                a {
                    padding: 0;
                }
                ul {
                    li {
                        padding-left: 10px;
                        margin-top: 12px !important;
                    }
                    a {
                        
                    }
                }
            }
            &-toggle {
                background: none;
                border: none;
                outline: none;
                position: absolute;
                top: 0px; right: 10px;
                z-index: 5;
                color: $color-gray;
                width: 30px; height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;
                &.active {
                    transform: scale(1, -1);
                }
                .icon {
                    width: 20px; height: 20px;
                }
                ul ul & {
                    top: 0;
                    height: 27px;
                }
            }
        }
        &__contacts {
            flex: 1 0 0;
            &-linkmap {
                font-size: 13px;
            }
            ul {
                // margin-bottom: 0;
                // padding-left: 10px;
            }
            li {
                font-size: 13px;
                & + li {
                    margin-top: 12px;
                }
            }
        }
        &__social {
            margin-top: 6px;
        }
        &__bottom {
            margin-top: 22px;
            gap: 10px 20px;
            flex-wrap: wrap;
            justify-content: flex-start;
            font-size: 12px;
        }
        &__sname {
            width: auto;
        }
    }
    @media (max-width: $md-mobile) {
        font-size: 13px;
        &__bottom {
            // font-size: 8px;
        }
    }
    @media (max-width: $md-mobile-smx) {
        &__intro {
            font-size: 10px;

        }
        &__nav ul {
            gap: 8px 14px;
        }
    }
}
