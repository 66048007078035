
.content__text {

    > * {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    a {
        
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    b {
        font-weight: 600;
    }
    h1, h2, h3, h4, h5 {
        color: $color-body;
        line-height: 1.3;
        // font-weight: 700;
    }
    h1 {
        font-size: 80px;
    }
    h2 {
        font-size: 48px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    ul,
    ol {
      padding-left: 20px;
    }
    ol {
        list-style-type: none; 
        counter-reset: num;
    }
    li {
        position: relative;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    ul li:before {
      position: absolute;
      top: 0px;
      right: 100%;
      margin-right: 11px; 
      color: $color-body;
      font-size: 16px;
      content: '•';
    }
    ol li:before {
      content: counter(num) '.'; 
      counter-increment: num;
      display: inline-block;	
      position: absolute;
      top: 0px;
      right: 100%;
      margin-right: 8px; 
      text-align: right;
    }
    img {
        border-radius: 20px;
    }

    @media (max-width: $md-desktop-md) {            
        h1 {
            font-size: 64px;
        }
        h2 {
            font-size: 36px;
        }
        h3 {
            font-size: 18px;
        }
        h4 {
            font-size: 16px;
        }
        h5 {
            font-size: 15px;
        }
    }
    @media (max-width: $md-tablet) {
        > * {
            margin-bottom: 10px;
        }
            
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 28px;
        }
        h3 {
            font-size: 18px;
        }
        h4 {
            font-size: 15px;
        }
        h5 {
            font-size: 14px;
        }
        li {
            margin-bottom: 5px;
        }
    }
    @media (max-width: $md-tablet-sm) {
        h3 {
            font-size: 16px;
        }
    }
}
