.menu {
    &__list {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    &__item {
        position: relative;
    }
    &__link {
        display: flex;
        align-items: center;
        gap: 5px;
        color: $color-body; font-size: 16px;

        transition: background 0.3s ease-in-out;
        &:after {
            position: absolute;
            bottom: 0; left: 0;
            right: 0;
            // width: 0; 
            transform: scaleX(0);
            transform-origin: right;
            content: '';
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);;
            border-bottom: 1px solid $color-green;
        }
        .icon {
            width: 25px;
            height: 25px;
        }
    }
    &__item.active > &__link,
    &__item:hover &__link {
        &:after {
            // width: 100%;
            transform-origin: left;
            transform: scaleX(1);
        }
        
    }
    &__item:hover &__link .icon {
       
    }

    @media (max-width: $md-desktop) {
        &__list {
            gap: 20px;
        }
        &__link {
            font-size: 14px;
        }
    }
}


.submenu {
    position: absolute;
    left: 0px;
    top: 100%;
    padding-top: 5px;
    min-width: 200px;
    transition: all 0.3s ease-in-out;
    transform: translateY(5px);
    opacity: 0; visibility: hidden;
    & & {
        left: 100%; top: -15px;
        margin-left: 10px;
    }
    .menu__item:hover > &,
    .submenu__item:hover & {
        opacity: 1; visibility: visible;
        transform: translateY(0px);
    }
    &__list {
        border-radius: 20px;
        background: #FFF;
        box-shadow: -4px 4px 8px 0px rgba(65, 58, 125, 0.05);
        backdrop-filter: blur(10px);
        padding: 10px;
    }
    &__item {
        position: relative;
    }
    &__link {
        padding: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-body;
        white-space: nowrap;
        transition: background 0.3s ease-in-out, color  0.3s ease-in-out;
        &:hover {
            // color: $color-blue;
           
        }
        .icon {
            width: 18px; height: 18px;
            color: $color-gray;
            // 
        }
    }
    .submenu__item:hover > &__link {
        background: $bg-gray-light;
        .icon {
            transform: rotate(-90deg);
        }
    }
}